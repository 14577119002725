<div class="credential-list">

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Columna de selección -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleSelectAll($event)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox [(ngModel)]="row.selected" (ngModelChange)="toggleRowSelection(row)"> </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="serviceName">
      <th mat-header-cell *matHeaderCellDef> Servicio </th>
      <td mat-cell *matCellDef="let row"> {{ row.serviceName }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let row">
        <button mat-button (click)="copyPassword(row.id)">Copiar Contraseña</button>
        <button mat-button (click)="viewDetails(row.id)">Ver Detalle</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="actions">
    <button mat-raised-button color="primary" (click)="selectAll()">Seleccionar Todo</button>
    <button mat-raised-button color="primary" (click)="deselectAll()">Deseleccionar Todo</button>
    <button mat-raised-button color="warn" (click)="deleteSelected()">Eliminar Seleccionados</button>
    <button mat-raised-button color="accent" (click)="editSelected()" [disabled]="selectedCredentials.size !== 1">
      Editar Seleccionado
    </button>
    <button mat-raised-button color="primary" (click)="addCredential()">Añadir Elemento</button>
  </div>
</div>
