<!-- UPPER HEADER -->
<header class="content-wrapper">

  <div class="hamburger-button" (click)="deployHamburger(menuIcon)" #menuIcon>
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </div>

  <div class="logo-wrapper">
    <a routerLink="/home">
      <img class="logo-desktop" src="../../../../assets/images/logo-mobile.webp" alt="Logo" />
    </a>
  </div>


  <nav class="sessionLanguageOptions">
    <ul>
      <li>
        <fa-icon [icon]="faGlobe"></fa-icon>
        <select
          [ngModel]="selectedLanguage"
          (ngModelChange)="selectLanguage($event)">
          <option value="es">
            {{ 'LANGUAGE.SPANISH' | translate }}
          </option>
          <option value="en">
            {{ 'LANGUAGE.ENGLISH' | translate }}
          </option>
          <option value="ca">
            {{ 'LANGUAGE.CATALAN' | translate }}
          </option>
        </select>

      </li>
      <li *ngIf="authService.isAuthenticated()"><a (click)="onLogout()">
        <fa-icon [icon]="faArrowRightFromBracket"></fa-icon><span>{{ 'HEADER.LOGOUT' | translate }}</span>
      </a></li>
    </ul>
  </nav>

  <nav class="add-button only-mobile">
    <fa-icon [icon]="faPlus"></fa-icon>
  </nav>

  <nav class="functionMenu">
    <ul class="vault">
      <li><a routerLink="/home" routerLinkActive="active">
        <fa-icon [icon]="faHouse"></fa-icon><span>{{ 'HEADER.HOME' | translate }}</span>
      </a></li>
      <li><a routerLink="/credential-list" routerLinkActive="active">
        <fa-icon [icon]="faAddressCard"></fa-icon><span>{{ 'HEADER.CREDENTIALS' | translate }}</span>
      </a></li>
      <li><a routerLink="/category-list" routerLinkActive="active">
        <fa-icon [icon]="faTags"></fa-icon><span>{{ 'HEADER.CATEGORIES' | translate }}</span>
      </a></li>
      <li><a (click)="syncVault()">
        <fa-icon [icon]="faArrowsRotate"></fa-icon><span>{{ 'HEADER.SYNC' | translate }}</span>
      </a></li>
    </ul>
    <ul class="profile">
      <li><a routerLink="/profile" routerLinkActive="active">
        <fa-icon [icon]="faUser"></fa-icon><span>{{ 'HEADER.USER_PROFILE' | translate }}</span>
      </a></li>
      <li><a routerLink="/change-password" routerLinkActive="active">
        <fa-icon [icon]="faTags"></fa-icon><span>{{ 'HEADER.CHANGE_PASSWORD' | translate }}</span>
      </a></li>
    </ul>
  </nav>
</header>


