<div class="credential-detail">
  <form [formGroup]="credentialForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="serviceName" required />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>URL</mat-label>
      <input matInput formControlName="serviceUrl" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Usuario</mat-label>
      <input matInput formControlName="username" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Contraseña</mat-label>
      <input matInput formControlName="password" type="password" />
    </mat-form-field>


    <mat-label>Categorías</mat-label>
<!--
    <mat-selection-list formControlName="categories">
      <mat-list-option *ngFor="let category of categories" [value]="category.id">
        {{ category.name }}
      </mat-list-option>
    </mat-selection-list>
-->
    <div class="mat-chip-alternative">
      <button
        mat-button
        type="button"
        *ngFor="let category of categories"
        [class.selected]="selectedCategories.includes(category.id)"
        (click)="toggleCategorySelection(category.id)">
        {{ category.name }}
      </button>
    </div>



    <button mat-raised-button color="primary" type="submit" [disabled]="credentialForm.invalid">
      Guardar
    </button>
    <button mat-raised-button color="warn" (click)="onCancel()">Cancelar</button>
  </form>
</div>
