<div class="credential-list">
  <div *ngIf="currentView === 'home'">
    <div class="category-buttons">
      <button
        mat-button
        type="button"
        *ngFor="let category of dataSource.data"
        [class.selected]="category.selected"
        (click)="onCategoryClick(category.id)">
        {{ category.name }}
      </button>
    </div>
  </div>

<div *ngIf="currentView === 'category-list'">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Columna de selección -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleSelectAll($event)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox [(ngModel)]="row.selected" (ngModelChange)="toggleRowSelection(row)"> </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Servicio </th>
      <td mat-cell *matCellDef="let row"> {{ row.name }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let row">
        <button mat-button (click)="viewDetails(row.id)">Ver Detalle</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="actions">
    <button mat-raised-button color="primary" (click)="selectAll()">Seleccionar Todo</button>
    <button mat-raised-button color="primary" (click)="deselectAll()">Deseleccionar Todo</button>
    <button mat-raised-button color="warn" (click)="deleteSelected()">Eliminar Seleccionados</button>
    <button mat-raised-button color="accent" (click)="editSelected()" [disabled]="selectedCategories.size !== 1">
      Editar Seleccionado
    </button>
    <button mat-raised-button color="primary" (click)="addCredential()">Añadir Elemento</button>
  </div>
</div>
</div>
