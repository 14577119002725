<div class="user-profile">
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
      <h1>Editar Perfil de Usuario</h1>
        <mat-card>
            <mat-card-header>
                <mat-card-title></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div>
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" />
                        <mat-error *ngIf="profileForm.get('name')?.invalid">
                            <span *ngIf="profileForm.get('name')?.hasError('required')">El nombre es obligatorio.</span>
                            <span *ngIf="profileForm.get('name')?.hasError('minlength')">Debe tener al menos 2 caracteres.</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>Apellido</mat-label>
                        <input matInput formControlName="surname" />
                        <mat-error *ngIf="profileForm.get('surname')?.invalid">
                            <span *ngIf="profileForm.get('surname')?.hasError('required')">El apellido es obligatorio.</span>
                            <span *ngIf="profileForm.get('surname')?.hasError('minlength')">Debe tener al menos 2 caracteres.</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>Correo Electrónico</mat-label>
                        <input matInput type="email" formControlName="email" />
                        <mat-error *ngIf="profileForm.get('email')?.invalid">
                            <span *ngIf="profileForm.get('email')?.hasError('required')">El correo electrónico es obligatorio.</span>
                            <span *ngIf="profileForm.get('email')?.hasError('email')">Formato inválido.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" [disabled]="!profileForm.valid">Guardar</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
