<div class="change-password">
    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Contraseña Antigua</mat-label>
            <input matInput type="password" formControlName="oldPassword" />
            <mat-error *ngIf="changePasswordForm.get('oldPassword')?.hasError('required')">
                La contraseña antigua es obligatoria.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Nueva Contraseña</mat-label>
            <input matInput type="password" formControlName="newPassword" />
            <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('required')">
                La nueva contraseña es obligatoria.
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('minlength')">
                La nueva contraseña debe tener al menos 6 caracteres.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Repetir Nueva Contraseña</mat-label>
            <input matInput type="password" formControlName="confirmNewPassword" />
            <mat-error *ngIf="changePasswordForm.hasError('passwordsMismatch')">
                Las contraseñas no coinciden.
            </mat-error>
        </mat-form-field>

        <button class="full-width" mat-raised-button color="primary" type="submit" [disabled]="!changePasswordForm.valid">Guardar Cambios</button>
    </form>
</div>
