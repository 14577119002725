
<div class="login-content">

  <header class="header">
    <img class="logo-image" src="assets/images/logo-desktop.webp" alt="Logo"/>
  </header>

  <main class="main">
    <h1>{{ 'LOGIN.TITLE' | translate }}</h1>
    <!-- FORMULARIO DE LOGIN -->
    <form (ngSubmit)="onLogin()" [formGroup]="loginForm" class="login-form">
      <!-- Campo 'email' -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'LOGIN.USER' | translate }}</mat-label>
        <input matInput formControlName="email" id="email" type="email" />
        <mat-error *ngIf="loginForm.get('email')?.hasError('required')">
          {{ 'WARNS.FIELD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="loginForm.get('email')?.hasError('email')">
          {{ 'WARNS.FIELD_EMAIL_FORMAT' | translate }}
        </mat-error>
      </mat-form-field>

      <!-- Campo 'password' -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
        <input matInput formControlName="password" id="password" type="password" />
        <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
          {{ 'WARNS.FIELD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="loginForm.get('password')?.hasError('minlength')">
          {{ 'WARNS.FIELD_CHARS_6_64' | translate }}
        </mat-error>
        <mat-error *ngIf="loginForm.get('password')?.hasError('maxlength')">
          {{ 'WARNS.FIELD_CHARS_6_64' | translate }}
        </mat-error>
      </mat-form-field>

      <!-- Botón de login -->
      <button
              mat-raised-button
              color="primary"
              [disabled]="!loginForm.valid"
              type="submit"
              class="full-width"
      >
        {{ 'LOGIN.BUT_LOGIN' | translate }}
      </button>
    </form>

    <!-- Enlace de registro -->
    <p class="signup-link">
      {{ 'LOGIN.NO_SIGNED' | translate }}
      <a routerLink="/signup">{{ 'LOGIN.LINK_SIGNUP' | translate }}</a>
    </p>
    <p class="signup-link">
      {{ 'LOGIN.FORGOTTEN_1' | translate }}
      <a routerLink="/request-delete">{{ 'LOGIN.LINK_DELETE' | translate }}</a>
      {{ 'LOGIN.FORGOTTEN_2' | translate }}
    </p>
  </main>


  <footer class="footer">
    <p>Esto es el footer</p>
  </footer>
</div>
