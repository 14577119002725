<footer class="device-wrapper">
  <footer class="layout upper-footer-layout">

  </footer>

  <!-- LOWER HEADER -->
  <footer class="layout lower-footer-layout">
    <div class="horiz-wrapper">

      <p>
        &copy; 2024 Marc Delgado Bonet.
        <img class="icon" src="../../../../assets/images/icon-linkedin.svg" alt=""/>
        <img class="icon" src="../../../../assets/images/icon-github.svg" alt=""/>
        <img class="icon" src="../../../../assets/images/icon-arroba.svg" alt=""/>
      </p>
      <p>
        This software is licensed under the <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License, Version 2.0</a>.
      </p>
    </div>
  </footer>
</footer>

